import '../styles/BenefitsPage.scss';

import Lottie from 'react-lottie';
import { Col, Row } from 'antd';
import VenVuuText from '../components/VenVuuText';

type BenefitsPageProps = {
  showDefaultPreRegisterModal: () => void;
  showAdvertiserPreRegisterModal: () => void;
};
const BenefitsPage = ({ showDefaultPreRegisterModal, showAdvertiserPreRegisterModal }: BenefitsPageProps) => {
  return (
    <>
      <Row justify="center" align="middle" gutter={50} style={{ margin: 20 }} className="hero-body box">
        <Col lg={10} xs={24}>
          <img src="img/logo-anim-black.svg" alt="Reach for the Metaverse" />
        </Col>
        <Col lg={9} xs={24}>
          <Lottie
            speed={0.1}
            options={
              {
                loop: true,
                path: 'https://assets1.lottiefiles.com/packages/lf20_2omr5gpu.json',
              } as any
            }
          />
        </Col>
      </Row>

      <section className="section benefits">
        <div className="columns is-multiline is-centered">
          <div className="column">
            <div className="block">
              <div className="block">
                <h2 className="title is-size-3">
                  Key Benefits of the <VenVuuText /> Dynamic NFT
                </h2>
              </div>
              <ul className="fa-ul mb-5">
                <li className="block">
                  <span className="fa-li">
                    <i className="fa-regular fa-hexagon fa-xs"></i>
                  </span>
                  Earn Revenue from Advertising in "Your World" or Wallet
                </li>
                <li className="block">
                  <span className="fa-li">
                    <i className="fa-regular fa-hexagon fa-xs"></i>
                  </span>
                  Easy to place in "Your World" gallery or space
                </li>
                <li className="block">
                  <span className="fa-li">
                    <i className="fa-regular fa-hexagon fa-xs"></i>
                  </span>
                  Lifetime Upgrades as the technology advances
                </li>
                <li className="block">
                  <span className="fa-li">
                    <i className="fa-regular fa-hexagon fa-xs"></i>
                  </span>
                  No Royalties
                </li>
                <li className="block">
                  <span className="fa-li">
                    <i className="fa-regular fa-hexagon fa-xs"></i>
                  </span>
                  Access to exclusive premium campaigns payouts
                </li>
              </ul>
              <div className="box has-text-centered">
                <h2 className="has-text-gray is-size-4">Stay up to date with the latest info</h2>
                <button className="button is-medium is-primary js-modal-trigger" onClick={showDefaultPreRegisterModal}>
                  Pre Register
                </button>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="block">
              <div className="block">
                <h1 className="title is-size-3 has-text-centered">
                  How it works for <VenVuuText /> Dynamic owners
                </h1>
              </div>
              <div className="column mx-5 mb-5" data-removed="true">
                <div className="is-relative notification is-link px-5">
                  <span className="ml-5 is-flex has-background-link is-justify-content-center is-align-items-center top-circle">
                    <span className="is-flex has-background-white has-text-link is-justify-content-center is-align-items-center circle-number">
                      <i className="fa-solid fa-1"></i>
                    </span>
                  </span>
                  <h4 className="mb-2 pt-4 is-size-5 has-text-centered">
                    Place the <VenVuuText /> Dynamic NFT on your Parcel or in Your Wallet
                  </h4>
                  <span className="mr-5 is-flex has-background-link is-justify-content-center is-align-items-center bottom-circle">
                    <span className="is-flex has-text-white is-justify-content-center is-align-items-center circle-number">
                      <i className="fa-solid fa-down-long fa-bounce"></i>
                    </span>
                  </span>
                </div>
              </div>
              <div className="column mx-5 mb-5" data-removed="true">
                <div className="is-relative notification is-info px-5">
                  <span className="ml-5 is-flex has-background-info is-justify-content-center is-align-items-center top-circle">
                    <span className="is-flex has-background-white has-text-info is-justify-content-center is-align-items-center circle-number">
                      <i className="fa-solid fa-2"></i>
                    </span>
                  </span>
                  <h4 className="mb-2 pt-4 is-size-5 has-text-centered">Approve the Campaigns you want to run</h4>
                  <span className="mr-5 is-flex has-background-info is-justify-content-center is-align-items-center bottom-circle">
                    <span className="is-flex has-text-white is-justify-content-center is-align-items-center circle-number">
                      <i className="fa-solid fa-down-long fa-bounce"></i>
                    </span>
                  </span>
                </div>
              </div>
              <div className="column mx-5" data-removed="true">
                <div className="is-relative notification is-primary px-5">
                  <span className="ml-5 is-flex has-background-primary is-justify-content-center is-align-items-center top-circle">
                    <span className="is-flex has-background-white has-text-primary is-justify-content-center is-align-items-center circle-number">
                      <i className="fa-solid fa-3"></i>
                    </span>
                  </span>
                  <h4 className="mb-2 pt-4 is-size-5 has-text-centered has-text-weight-semibold">Get Paid!</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="columns is-multiline is-centered">
          <div className="column">
            <div className="block">
              <div className="block">
                <h2 className="mainhead is-size-3">Key Benefits for Advertisers</h2>
              </div>
              <ul className="fa-ul mb-5">
                <li className="block">
                  <span className="fa-li">
                    <i className="fa-regular fa-hexagon fa-xs"></i>
                  </span>
                  Ads, Videos, and 3D images for product placement can be placed in one or unlimited number of Metaverse Parcels and/or wallets simultaneously
                </li>
                <li className="block ">
                  <span className="fa-li">
                    <i className="fa-regular fa-hexagon fa-xs"></i>
                  </span>
                  In World targeting to reach the right parcels and placements
                </li>
                <li className="block">
                  <span className="fa-li">
                    <i className="fa-regular fa-hexagon fa-xs"></i>
                  </span>
                  Run Multi-Metaverse campaigns
                </li>
                <li className="block">
                  <span className="fa-li">
                    <i className="fa-regular fa-hexagon fa-xs"></i>
                  </span>
                  Proprietary Analytics Engine
                </li>
                <li className="block">
                  <span className="fa-li">
                    <i className="fa-regular fa-hexagon fa-xs"></i>
                  </span>
                  Reusable Campaign Manager NFT
                </li>
              </ul>
              <div className="box has-text-centered">
                <h2 className="has-text-gray is-size-4">Get all the info you need to become an Advertiser</h2>
                <button className="button is-medium is-primary js-modal-trigger" onClick={showAdvertiserPreRegisterModal}>
                  Pre Register
                </button>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="block">
              <div className="block">
                <h1 className="title is-size-3 has-text-centered">How it Works for Advertisers</h1>
              </div>
              <div className="column mx-5 mb-5" data-removed="true">
                <div className="is-relative notification is-link px-5">
                  <span className="ml-5 is-flex has-background-link is-justify-content-center is-align-items-center top-circle">
                    <span className="is-flex has-background-white has-text-link is-justify-content-center is-align-items-center circle-number">
                      <i className="fa-solid fa-1"></i>
                    </span>
                  </span>
                  <h4 className="mb-2 pt-4 is-size-5 has-text-centered">
                    Load Your Campaign into the <VenVuuText /> Ad NFT
                  </h4>
                  <span className="mr-5 is-flex has-background-link is-justify-content-center is-align-items-center bottom-circle">
                    <span className="is-flex has-text-white is-justify-content-center is-align-items-center circle-number">
                      <i className="fa-solid fa-down-long fa-bounce"></i>
                    </span>
                  </span>
                </div>
              </div>
              <div className="column mx-5 mb-5" data-removed="true">
                <div className="is-relative notification is-info px-5">
                  <span className="ml-5 is-flex has-background-info is-justify-content-center is-align-items-center top-circle">
                    <span className="is-flex has-background-white has-text-info is-justify-content-center is-align-items-center circle-number">
                      <i className="fa-solid fa-2"></i>
                    </span>
                  </span>
                  <h4 className="mb-2 pt-4 is-size-5 has-text-centered">Target Your Campaign Offer</h4>
                  <span className="mr-5 is-flex has-background-info is-justify-content-center is-align-items-center bottom-circle">
                    <span className="is-flex has-text-white is-justify-content-center is-align-items-center circle-number">
                      <i className="fa-solid fa-down-long fa-bounce"></i>
                    </span>
                  </span>
                </div>
              </div>
              <div className="column mx-5" data-removed="true">
                <div className="is-relative notification is-primary px-5">
                  <span className="ml-5 is-flex has-background-primary is-justify-content-center is-align-items-center top-circle">
                    <span className="is-flex has-background-white has-text-primary is-justify-content-center is-align-items-center circle-number">
                      <i className="fa-solid fa-3"></i>
                    </span>
                  </span>
                  <h4 className="mb-2 pt-4 is-size-5 has-text-centered has-text-weight-semibold">Get Results &amp; Analytics</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="columns is-multiline is-centered">
          <div className="column">
            <div className="block">
              <h1 className="mainhead is-size-3">
                <VenVuuText /> NFT AD PLATFORM
              </h1>
              <p className="subtitle has-text-grey mb-5">
                As a landowner in the Metaverse, why not start earning revenue from your investments almost immediately! The "<VenVuuText /> Dynamic Content" NFT allows you to easily get paid for ads,
                product placements, wearables and much more in the virtual worlds you have created. Not to mention, earning even while your "<VenVuuText /> Dynamic" sits in your wallet. Each "
                <VenVuuText /> Dynamic" is a unique NFT that is like owning your own multi-media outlet that you own and control in "Your World". Brands and Advertisers can easily stream their
                messages and content to your "<VenVuuText /> Dynamic" with your approval, and you get paid.
              </p>
              <p className="subtitle has-text-grey mb-5">
                Currently there are as many as 268,645 parcels in the "Big Four" Metaverses worth billions of dollars, and millions of wallets holding NFT's. Brands today realize it is very important
                to get their messages and products placed into the Metaverse, however up until now there has been no easy way to reach them. The "<VenVuuText /> Dynamic Content" ad platform brings
                both landowners and advertisers together to make it an easy process to monetize your world, while allowing advertisers to reach thousands of different worlds simultaneously.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BenefitsPage;
