import '../styles/HomePage.scss';

import { Carousel } from 'antd';
import { Link } from 'react-router-dom';
import VenVuuText from '../components/VenVuuText';

const contentStyle: React.CSSProperties = {
  color: '#fff',
  lineHeight: '160px',
  fontSize: '2em',
  textAlign: 'center',
};

type HomePageProps = {
  showDefaultPreRegisterModal: () => void;
  showAdvertiserPreRegisterModal: () => void;
};
const HomePage = ({ showDefaultPreRegisterModal, showAdvertiserPreRegisterModal }: HomePageProps) => {
  return (
    <>
      <section className="block">
        <div className="intro">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-7">
                <div className="block">
                  <div className="column is-8 m-4">
                    <img src="img/logo-anim-black.svg" alt="Reach for the Metaverse" />
                  </div>
                </div>
                <div className="block m-3">
                  <div className="fit mainhead" style={{ fontSize: '32px', color: '#4a4a4a' }}>
                    Reach for the
                  </div>
                  <div className="fit mainhead" style={{ fontSize: '48px', fontWeight: 700, color: '#4a4a4a' }}>
                    Metaverse
                  </div>
                </div>
                <div className="block m-3">
                  <h2 className="fit subtitle is-uppercase">Metaverse Advertising &amp; NFT Monetization Platform</h2>
                </div>
              </div>
              <div className="column is-5 intro-details">
                <div className="box m-5 introducing has-text-centered">
                  <div className="block">
                    <div className="fit mainhead has-text-white">Introducing</div>
                    <div className="fit mainhead has-text-white" style={{ fontSize: '46px' }}>
                      Dynamic NFT's
                    </div>
                  </div>
                  <div className="divider block is-warning">
                    <i className="fa-solid fa-hexagon-vertical-nft-slanted fa-2x has-text-warning"></i>
                  </div>
                  <Carousel autoplay autoplaySpeed={1500}>
                    <div>
                      <h3 style={contentStyle}>Earn Revenue</h3>
                    </div>
                    <div>
                      <h3 style={contentStyle}>Dynamic Content NFT</h3>
                    </div>
                    <div>
                      <h3 style={contentStyle}>No Royalties</h3>
                    </div>
                    <div>
                      <h3 style={contentStyle}>Multi-Chain Platform</h3>
                    </div>
                  </Carousel>
                  <div className="block has-text-right">
                    <Link to="/benefits" className="has-text-white is-underlined is-italic">
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="box m-5 join-discord has-text-centered">
                  <a href="https://discord.gg/5dbNTPTR22" target="_blank" rel="noopener noreferrer">
                    <img style={{ width: '240px' }} src="img/discord.png" alt="Join us on Discord" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="block">
        <div className="columns is-multiline is-centered m-2">
          <div className="column is-11-desktop has-text-justified">
            <div className="block">
              <h2 className="fit mainhead">Introducing the World's First NFT Ad Platform</h2>
            </div>
            <p className="subtitle has-text-grey mb-5">
              As a landowner in the Metaverse, why not start earning revenue from your investments almost immediately! The "<VenVuuText /> Dynamic Content" NFT allows you to easily get paid for ads,
              product placements, wearables and much more in the virtual worlds you have created. Not to mention, earning even while your "<VenVuuText /> Dynamic" sits in your wallet. Each "
              <VenVuuText /> Dynamic" is a unique NFT that is like owning your own multi-media outlet that you own and control in "Your World". Brands and Advertisers can easily stream their messages
              and content to your "<VenVuuText /> Dynamic" with your approval, and you get paid.
            </p>
            <p className="subtitle has-text-grey mb-5">
              Currently there are as many as 268,645 parcels in the "Big Four" Metaverses worth billions of dollars, and millions of wallets holding NFT's. Brands today realize it is very important to
              get their messages and products placed into the Metaverse, however up until now there has been no easy way to reach them. The "<VenVuuText /> Dynamic Content" ad platform brings both
              landowners and advertisers together to make it an easy process to monetize your world, while allowing advertisers to reach thousands of different worlds simultaneously.
            </p>
          </div>
        </div>
      </section>
      <section className="block container">
        <div className="container">
          <div className="columns iwant">
            <div className="column m-2">
              <div className="card notification is-primary has-text-centered feature advertise">
                <div className="columns">
                  <div className="column">
                    <p className="title">
                      I want to
                      <br />
                      Advertise
                    </p>
                  </div>
                </div>
                <div className="content">
                  <button className="button is-large js-modal-trigger" onClick={showAdvertiserPreRegisterModal}>
                    Reach the Metaverse
                  </button>
                </div>
              </div>
            </div>
            <div className="column m-2">
              <div className="card notification is-info has-text-centered feature monetize">
                <div className="columns">
                  <div className="column">
                    <p className="title">
                      I want to
                      <br />
                      Monetize
                    </p>
                  </div>
                </div>
                <div className="content">
                  <Link to="/benefits" className="button is-large">
                    Earn Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;
