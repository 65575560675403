import { whitelist as whitelisted } from '../constants';
import { MerkleTree } from 'merkletreejs';
const keccak256 = require('keccak256');

export const isWhitelisted = (address: string): boolean => {
  return whitelisted.includes(address);
};

export const getMerkleProof = ({ whitelisted, tree }: { whitelisted: string; tree: any }) => {
  return tree.getHexProof(keccak256(whitelisted));
};

export const generateMerkleTree = (addresses: String[]) => {
  const leafs = addresses.map((addr) => keccak256(addr));
  return new MerkleTree(leafs, keccak256, { sortPairs: true });
};
