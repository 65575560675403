import './style.scss';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ContactUsModal from '../ContactUsModal';

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isContactUsShowing, setIsContactUsShowing] = useState(false);

  return (
    <>
      <ContactUsModal
        isModalVisible={isContactUsShowing}
        handleCancel={() => {
          setIsContactUsShowing(false);
        }}
      />
      <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="container">
          <div className="navbar-brand">
            <div role="button" className={!isNavOpen ? 'navbar-burger' : 'navbar-burger is-active'} data-target="navMenu" onClick={() => setIsNavOpen(!isNavOpen)}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>
          <div id="navMenu" className={isNavOpen ? 'navbar-menu is-active' : 'navbar-menu'}>
            <div className="navbar-start">
              <Link to="/" className="navbar-item" onClick={() => setIsNavOpen(false)}>
                Home
              </Link>
              <Link to="/about" className="navbar-item" onClick={() => setIsNavOpen(false)}>
                About
              </Link>
              <Link to="/roadmap" className="navbar-item" onClick={() => setIsNavOpen(false)}>
                Roadmap
              </Link>
              <Link to="/team" className="navbar-item" onClick={() => setIsNavOpen(false)}>
                Team
              </Link>
              <Link to="/benefits" className="navbar-item" onClick={() => setIsNavOpen(false)}>
                Benefits
              </Link>
              <a href="https://datchat.com/investors/" rel="noreferrer" target="_blank" className="navbar-item">
                Investors
              </a>
              <Link to="/whitepaper" className="navbar-item" onClick={() => setIsNavOpen(false)}>
                Whitepaper
              </Link>
              <Link to="/faq" className="navbar-item" onClick={() => setIsNavOpen(false)}>
                FAQ
              </Link>
              <span
                className="navbar-item contact-us-link"
                onClick={() => {
                  setIsContactUsShowing(true);
                }}
              >
                Contact Us
              </span>
            </div>
            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <a href="https://dat.chat/p/venvuu" rel="noreferrer" target="_blank" className="button is-white has-text-weight-normal datlink" onClick={() => setIsNavOpen(false)}>
                    <i className="fak fa-datchat"></i>
                  </a>
                  <a href="https://twitter.com/Venvuu_Datchat" rel="noreferrer" target="_blank" className="button is-white has-text-weight-normal headbrand" onClick={() => setIsNavOpen(false)}>
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                  <a href="https://discord.gg/5dbNTPTR22" rel="noreferrer" target="_blank" className="button is-white has-text-weight-normal headbrand" onClick={() => setIsNavOpen(false)}>
                    <i className="fa-brands fa-discord"></i>
                  </a>
                  <button className="button is-primary is-inverted is-static has-text-dark" disabled style={{ opacity: 1 }} onClick={() => setIsNavOpen(false)}>
                    NASDAQ: DATS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
