import './style.scss';

import { Col, Modal, Row } from 'antd';
import { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

export enum PreRegisterType {
  DEFAULT = 0,
  ADVERTISERS,
}

type PreRegisterModalProps = {
  isModalVisible: boolean;
  handleCancel: () => void;
};
const ContactUsModal = ({ isModalVisible, handleCancel }: PreRegisterModalProps) => {
  const [isFormCreated, setIsFormCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isModalVisible && !isFormCreated) {
      setIsLoading(true);

      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          region: 'na1',
          portalId: '21538426',
          formId: '64571885-8a95-45be-86aa-4c9506047809',
          target: '#hubspotForm',
        });
      }

      setIsFormCreated(true);
      setIsLoading(false);
    }
  }, [isFormCreated, isModalVisible]);

  return (
    <>
      <Modal visible={isModalVisible} footer={null} centered={true} closable={false} bodyStyle={{ padding: 0, margin: 0 }} onCancel={handleCancel}>
        {isLoading ? (
          <Row align="middle" justify="center" style={{ padding: 30 }}>
            <ClipLoader color={'#d5b1ff'} loading={true} size={150} />
          </Row>
        ) : (
          <Row className="modal-box" justify="center" align="middle">
            <div className="modal-content">
              <div className="box">
                <Row justify="center">
                  <Col>Contact Us!</Col>
                </Row>
                <hr style={{ border: '1px solid grey' }} />
                <div id="hubspotForm" />
              </div>
            </div>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default ContactUsModal;
