import '../styles/RoadmapPage.scss';
import Lottie from 'react-lottie';
import { Col, Row } from 'antd';
import VenVuuText from '../components/VenVuuText';

const RoadmapPage = () => {
  return (
    <>
      <Row justify="center" align="middle" gutter={50} style={{ margin: 20 }} className="hero-body box">
        <Col lg={10} xs={24}>
          <img src="img/logo-anim-black.svg" alt="Reach for the Metaverse" />
          <p className="subtitle">
            <span className="icon">
              <i className="fa-light fa-location-dot"></i>
            </span>
            Project Roadmap
          </p>
        </Col>
        <Col lg={9} xs={24}>
          <Lottie
            speed={0.1}
            options={
              {
                loop: true,
                path: 'https://assets9.lottiefiles.com/private_files/lf30_nhg4au0e.json',
              } as any
            }
          />
        </Col>
      </Row>

      <section>
        <div className="timeline is-centered mb-6">
          <header className="timeline-header">
            <span className="tag is-medium is-primary">2021</span>{' '}
          </header>
          <div className="timeline-item is-primary">
            <div className="timeline-marker is-primary is-icon">
              {' '}
              <i className="fa fa-lightbulb"></i>{' '}
            </div>
            <div className="timeline-content">
              <p className="heading">November 2021</p>
              <p>Idea is born for a reusable NFT &amp; Metaverse Ad Network</p>
            </div>
          </div>

          <div className="timeline-item is-primary">
            <div className="timeline-marker is-primary is-icon">
              {' '}
              <i className="fa-solid fa-code"></i>{' '}
            </div>
            <div className="timeline-content">
              <p className="heading">December 2021</p>
              <p>Programming begins on the reusable NFT &amp; Platform</p>
            </div>
          </div>

          <header className="timeline-header">
            {' '}
            <span className="tag is-medium is-primary">2022</span>{' '}
          </header>
          <div className="timeline-item is-primary">
            <div className="timeline-marker is-primary is-icon">
              {' '}
              <i className="fa-solid fa-handshake-angle"></i>{' '}
            </div>
            <div className="timeline-content">
              <p className="heading">January 2022</p>
              <p>Company agrees to Acquire Avila Security Corp. and their Blockchain &amp; Communication and Communications patents</p>
            </div>
          </div>

          <div className="timeline-item is-primary">
            <div className="timeline-marker is-primary is-icon">
              {' '}
              <i className="fa-solid fa-rocket"></i>{' '}
            </div>
            <div className="timeline-content">
              <p className="heading">March 2022</p>
              <p>
                Company Launches Website &amp; opens Pre-Sale registration of the <VenVuuText /> NFT
              </p>
            </div>
          </div>

          <div className="timeline-item is-warning">
            <div className="timeline-marker is-warning is-icon">
              {' '}
              <i className="fa-solid fa-hexagon-vertical-nft-slanted"></i>{' '}
            </div>
            <div className="timeline-content">
              <p className="heading">May 2022</p>
              <p>
                Company will drop the <VenVuuText /> Dynamic NFT Generation Zero
              </p>
            </div>
          </div>
          <div className="timeline-item is-warning">
            <div className="timeline-marker is-warning is-icon">
              {' '}
              <i className="fa-solid fa-party-horn"></i>{' '}
            </div>
            <div className="timeline-content">
              <p className="heading">June 2022</p>
              <p>Community Event: Landowners, Advertisers and NFT holders event</p>
            </div>
          </div>
          <div className="timeline-item is-warning">
            <div className="timeline-marker  is-warning is-icon">
              {' '}
              <i className="fa-solid fa-rectangle-ad"></i>{' '}
            </div>
            <div className="timeline-content">
              <p className="heading">July 2022</p>
              <p>
                Company will Launch first paid Ad Campaign on the <VenVuuText /> Platform and open the platform to other Advertisers
              </p>
            </div>
          </div>
          <div className="timeline-item is-warning">
            <div className="timeline-marker  is-warning is-icon">
              {' '}
              <i className="fa-solid fa-rectangle-ad"></i>{' '}
            </div>
            <div className="timeline-content">
              <p className="heading">July 2022</p>
              <p>2022 Agency partners reveal</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-marker notification is-icon">
              {' '}
              <i className="fa-solid fa-hexagon-vertical-nft-slanted"></i>{' '}
            </div>
            <div className="timeline-content">
              <p className="heading">Q3 2022</p>
              <p>
                Company will drop the <VenVuuText /> Dynamic NFT Generation One
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-marker notification is-icon">
              {' '}
              <i className="fa-solid fa-cubes"></i>{' '}
            </div>
            <div className="timeline-content">
              <p className="heading">Q3 2022</p>
              <p>
                <VenVuuText /> Pre-UpFronts – First Metaverse Upfront. <VenVuuText /> invites AD Agencies to view <VenVuuText /> billboard inventory, capabilities and opportunities
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-marker notification is-icon">
              {' '}
              <i className="fa-solid fa-cubes"></i>{' '}
            </div>
            <div className="timeline-content">
              <p className="heading">Q4 2022</p>
              <p>
                Company will upgrade the <VenVuuText /> NFT to serve 3D images and wearables
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-marker notification is-icon">
              {' '}
              <i className="fa-solid fa-handshake-angle"></i>{' '}
            </div>
            <div className="timeline-content">
              <p className="heading">Q4 2022</p>
              <p>Company will launch rentable NFT’s</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-marker notification is-icon">
              {' '}
              <i className="fa-solid fa-handshake-angle"></i>{' '}
            </div>
            <div className="timeline-content">
              <p className="heading">Q4 2022</p>
              <p>
                <VenVuuText /> UpFronts Pre sale - <VenVuuText /> invites AD Agencies to view <VenVuuText /> billboard inventory, capabilities, and opportunities
              </p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-marker notification is-icon">
              {' '}
              <i className="fa-solid fa-hexagon-vertical-nft-slanted"></i>{' '}
            </div>
            <div className="timeline-content">
              <p className="heading">Q4 2022</p>
              <p>
                Company will drop its 2nd <VenVuuText /> reusable and revenue-producing NFT
              </p>
            </div>
          </div>
          <header className="timeline-header">
            <span className="tag is-medium notification">
              <i className="fa-solid fa-shuttle-space mr-2"></i>And Beyond
            </span>
          </header>
        </div>
      </section>
    </>
  );
};

export default RoadmapPage;
