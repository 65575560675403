import { Col, Row } from 'antd';
import Lottie from 'react-lottie';
import VenVuuText from '../components/VenVuuText';

const AboutPage = () => {
  return (
    <>
      <Row justify="center" align="middle" gutter={50} style={{ margin: 20 }} className="hero-body box">
        <Col lg={10} xs={24}>
          <img src="img/logo-anim-black.svg" alt="Reach for the Metaverse" />
        </Col>
        <Col lg={9} xs={24}>
          <Lottie
            speed={0.1}
            options={
              {
                loop: false,
                path: 'https://assets8.lottiefiles.com/packages/lf20_lv0auvzz.json',
              } as any
            }
          />
        </Col>
      </Row>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline is-centered">
            <div className="column is-8 is-6-desktop">
              <p className="subtitle has-text-grey mb-5">
                <VenVuuText /> is an innovative Metaverse advertising platform that allows advertisers and metaverse landowners to connect using our proprietary metaverse ad network and dynamic NFT
                technology. Landowners have an easy and consolidated way to decide which campaigns to run to generate revenue from their holdings. Advertisers can run campaigns that target the land
                parcels they want to reach simultaneously across multiple metaverses. <VenVuuText /> is both owned and operated by DatChat, Inc., and developed using its patented DRM and encryption
                technology. (Nasdaq:DATS)
              </p>
              <p className="subtitle has-text-grey mb-5">
                DatChat Inc. is a blockchain, digital rights management, cybersecurity, and social media company that not only focuses on protecting our privacy on our devices, but also protecting our
                information after we have shared it with others. We believe an individual's right to privacy should not end the moment they click "send", and that we all deserve the same right to
                privacy online that we enjoy in our own living rooms. Our flagship product, the DatChat Messenger &amp; Private Social Network, is a privacy platform and mobile application that
                provides users the ability to communicate and share with the privacy and protection they deserve.
              </p>
              <p className="subtitle has-text-grey mb-5">
                Our patented technology allows users to exercise control over their messages and posts, even after they are sent. Through our application, users can delete messages that they send on
                their own device and the recipient's device as well. There is no set time limit within which they must exercise this choice. A user can elect at any time to delete a message previously
                sent to a recipient's device. In addition, users can delete entire conversations at any time, making it like the conversation never even happened. Additionally, our application
                includes a screenshot protection system, which makes it virtually impossible for the recipient to screenshot a message or picture before it gets destroyed.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutPage;
