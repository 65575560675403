import { Col, Row } from 'antd';
import Lottie from 'react-lottie';

const TeamPage = () => {
  return (
    <>
      <Row justify="center" align="middle" style={{ margin: 20 }} className="hero-body box" gutter={30}>
        <Col lg={10} xs={24}>
          <img src="img/logo-anim-black.svg" alt="Reach for the Metaverse" />
        </Col>
        <Col lg={9} xs={24}>
          <Lottie
            speed={0.1}
            options={
              {
                loop: true,
                path: 'https://assets3.lottiefiles.com/packages/lf20_9xrenieu.json',
              } as any
            }
          />
        </Col>
      </Row>

      <section className="section team">
        <h1 className="title has-text-centered">Meet the Team</h1>
        <div className="container">
          <div className="columns is-multiline is-centered">
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/darin.png" alt="Darin Myman" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Darin Myman</h4>
                <p className="has-text-grey">CEO &amp; Founder</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/pete.png" alt="Peter Shelus" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Peter Shelus</h4>
                <p className="has-text-grey">CTO &amp; Founder</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/jeff.png" alt="Jeff Stark" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Jeff Stark</h4>
                <p className="has-text-grey">UX &amp; Founder</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/mark.png" alt="Mark Mathis" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Mark Mathis</h4>
                <p className="has-text-grey">Chief Blockchain Architect</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/gabe.png" alt="Gabe Daniels" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Gabe Daniels</h4>
                <p className="has-text-grey">CIO</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/gianfranco.png" alt="Gianfranco Lopane" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Gianfranco Lopane</h4>
                <p className="has-text-grey">Head of Business Development</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/brett.png" alt="Brett Blumberg" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Brett Blumberg</h4>
                <p className="has-text-grey">CFO</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/alex.png" alt="Alexei Tontici" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Alexei Tontici</h4>
                <p className="has-text-grey">Engineer</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/katie.png" alt="Katie Hamon" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Katie Hamon</h4>
                <p className="has-text-grey">Engineer</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/dan.png" alt="Dan Miller" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Dan Miller</h4>
                <p className="has-text-grey">Engineer</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/patrick.png" alt="Patrick Moscova" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Patrick Moscova</h4>
                <p className="has-text-grey">Engineer</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/asif.png" alt="Asif Chauhan" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Asif Chauhan</h4>
                <p className="has-text-grey">Engineer</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/rick.png" alt="Rick Twohy" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Rick Twohy</h4>
                <p className="has-text-grey">Engineer</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/sanjay.png" alt="Sanjay CK" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Sanjay CK</h4>
                <p className="has-text-grey">Engineer</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/stephen.png" alt="Stephen Payne" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Stephen Payne</h4>
                <p className="has-text-grey">Engineer</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/harrison.png" alt="Harrison Ellis" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Harrison Ellis</h4>
                <p className="has-text-grey">Engineer</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/glenn.png" alt="Glenn Nelson" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Glenn Nelson</h4>
                <p className="has-text-grey">Marketing</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/pharoah.png" alt="Pharoah Jefferson" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Pharoah Jefferson</h4>
                <p className="has-text-grey">Marketing</p>
              </div>
            </div>
            <div className="column is-6 is-4-desktop mb-4">
              <div className="pb-5 box has-text-centered member">
                {' '}
                <img className="mx-auto mb-5 image is-fullwidth" src="img/team/bruce.png" alt="Bruce Van Heel" />
                <h4 className="mb-2 is-size-5 has-text-weight-bold">Bruce Van Heel</h4>
                <p className="has-text-grey">Marketing</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TeamPage;
