import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { AboutPage, BenefitsPage, FAQPage, HomePage, NotFoundPage, RoadmapPage, TeamPage, WhitepaperPage } from '.';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PreRegisterModal, { PreRegisterType } from '../components/PreRegisterModal';
import { MINT_STATE, react_app_mint_address, rpc_url } from '../constants';
import { ethers } from 'ethers';
import { CURRENT_MINT_STATE } from '../constants/index';
import { Col, Row } from 'antd';
import ClipLoader from 'react-spinners/ClipLoader';

const abi = ['function status() view returns (uint8)'];

const Layout = () => {
  const [background, setBackground] = useState('none');
  const [isInactive, setInactive] = useState(true);
  const [loading, setLoading] = useState(true);

  let location = useLocation();

  const [isPreRegisterModalShowing, setIsPreRegisterModalShowing] = useState(false);
  const [preRegisterType, setPreRegisterType] = useState(PreRegisterType.DEFAULT);

  const showDefaultPreRegisterModal = () => {
    setIsPreRegisterModalShowing(true);
    setPreRegisterType(PreRegisterType.DEFAULT);
  };

  const showAdvertiserPreRegisterModal = () => {
    setIsPreRegisterModalShowing(true);
    setPreRegisterType(PreRegisterType.ADVERTISERS);
  };

  const hidePreRegisterModal = () => {
    setIsPreRegisterModalShowing(false);
  };

  useEffect(() => {
    const contract = new ethers.Contract(react_app_mint_address, abi, new ethers.providers.JsonRpcProvider(rpc_url));

    const checkStatus = () => {
      contract
        .status()
        .then((status: number) => {
          setInactive(status === MINT_STATE.INACTIVE);
          setLoading(false);
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
    checkStatus();

    const intervalID = setInterval(() => {
      checkStatus();
    }, 2 * 1000);

    if (!loading) {
      setBackground(location.pathname === '/mint' && (isInactive || CURRENT_MINT_STATE === MINT_STATE.INACTIVE) ? 'url("img/map.png")' : 'none');
    }

    return () => clearInterval(intervalID);
  }, [location.pathname, isInactive, loading]);

  return (
    <>
      <PreRegisterModal preRegisterType={preRegisterType} isModalVisible={isPreRegisterModalShowing} handleCancel={hidePreRegisterModal} />
      <div
        className="site"
        style={{
          backgroundImage: background,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="container">
          <div className="columns">
            <div className="column">
              <Navbar />
              {loading ? (
                <>
                  <>
                    <Row justify="center" align="middle" style={{ height: '100vh' }}>
                      <Col>
                        <ClipLoader color={'#d5b1ff'} loading={true} size={150} />
                      </Col>
                    </Row>
                  </>
                </>
              ) : (
                <>
                  <Routes>
                    <Route path="/" element={<HomePage showDefaultPreRegisterModal={showDefaultPreRegisterModal} showAdvertiserPreRegisterModal={showAdvertiserPreRegisterModal} />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/roadmap" element={<RoadmapPage />} />
                    <Route path="/team" element={<TeamPage />} />
                    <Route path="/benefits" element={<BenefitsPage showDefaultPreRegisterModal={showDefaultPreRegisterModal} showAdvertiserPreRegisterModal={showAdvertiserPreRegisterModal} />} />
                    <Route path="/whitepaper" element={<WhitepaperPage />} />
                    <Route path="/faq" element={<FAQPage />} />
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
