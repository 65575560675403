import { Col, Row } from 'antd';

const NotFoundPage = () => {
  return (
    <>
      <Row
        style={{ height: '50vh', fontSize: '6em', color: '#454545' }}
        align="middle"
        justify="center"
      >
        <Col>Page Not Found</Col>
      </Row>
    </>
  );
};

export default NotFoundPage;
