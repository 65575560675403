import './style.scss';
import { Col, Divider, Row } from 'antd';
import Icon from '@ant-design/icons';

import metaMaskIcon from '../../../../assets/metamask.png';
import walletConnectIcon from '../../../../assets/wallet-connect.png';

import { Web3Provider } from '@ethersproject/providers';
import { ethers } from 'ethers';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { react_app_env, rpc_id, rpc_name, rpc_url } from '../../../../constants';
import { toChecksumAddress } from '../../../../utils';
import { useEffect, useState } from 'react';

const networks = {
  rinkeby: { chainId: `0x${Number(4).toString(16)}` },
  homestead: { chainId: `0x${Number(1).toString(16)}` }, // this is the main net
};

type SelectWalletProps = {
  onConnectMetaSuccess: (provider: Web3Provider, account: string) => void;
  onConnectWCSuccess: (provider: Web3Provider, account: string) => void;
};

function SelectWallet({ onConnectMetaSuccess, onConnectWCSuccess }: SelectWalletProps) {
  const [deviceType, setDeviceType] = useState('');

  const onMetaMaskSelect = async () => {
    const { ethereum } = window;
    if (ethereum) {
      console.log('Ethereum successfully detected!');
    } else {
      window.location.replace(`https://metamask.app.link/dapp/${window.location.href}`);
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const providerNetwork = await provider.getNetwork();

    let requiredNet = react_app_env !== 'prod' ? 'rinkeby' : 'homestead';

    if (requiredNet !== providerNetwork.name) {
      try {
        if (react_app_env !== 'prod') {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ ...networks['rinkeby'] }],
          });
        } else {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ ...networks['homestead'] }],
          });
        }
        const updatedProvider = new ethers.providers.Web3Provider(window.ethereum);
        // MetaMask requires requesting permission to connect users accounts
        updatedProvider
          .send('eth_requestAccounts', [])
          .then((account) => {
            if (account !== '') {
              let address = toChecksumAddress(account[0]);
              onConnectMetaSuccess(updatedProvider, address);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        toast.error('Error connecting to MetaMask', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      provider
        .send('eth_requestAccounts', [])
        .then((account) => {
          if (account !== '') {
            let address = toChecksumAddress(account[0]);
            onConnectMetaSuccess(provider, address);
          }
        })
        .catch((err) => {
          toast.error('Error connecting to MetaMask', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  const onWCSelect = async () => {
    const providerWC = new WalletConnectProvider({
      rpc: {
        [rpc_id]: rpc_url,
      },
      qrcodeModalOptions: {
        mobileLinks: ['rainbow', 'metamask', 'argent', 'trust', 'imtoken', 'pillar'],
      },
    });

    providerWC.onConnect(() => {
      if (providerWC.chainId !== rpc_id) {
        console.log(providerWC.chainId, 'Connected to wrong network');
        // POP TOAST
        toast.error(`Please connect wallet to ${rpc_name}`, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        providerWC.close();
        return;
      }
    });

    providerWC.on('chainChanged', (chainId: number) => {
      if (chainId !== rpc_id) {
        toast.error(`Please connect wallet to ${rpc_name}`, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        providerWC.close();
      }
    });

    console.log(providerWC);

    //  Enable session (triggers QR Code modal)
    try {
      const address = await providerWC.enable();

      if (!providerWC.connected) {
        console.log('rejected');
        return;
      }
      if (providerWC.chainId !== rpc_id) {
        console.log(providerWC.chainId, 'Connected to wrong network');
        return;
      }

      onConnectWCSuccess(new ethers.providers.Web3Provider(providerWC), address[0]);
    } catch {
      console.log('rejected');
      toast.error('Error connecting to wallet, please try again', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  };

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)) {
      setDeviceType('Mobile');
    } else {
      setDeviceType('Desktop');
    }
  }, [deviceType]);

  return (
    <>
      <Row justify="center" align="middle">
        <Col span={24}>
          <Row className="wallet-button top" justify="center" align="middle" onClick={onMetaMaskSelect}>
            <Col span={24}>
              <Row justify="center" align="middle">
                <Col span={5}>
                  <Icon component={() => <img alt="metamask" src={metaMaskIcon} />} />
                </Col>
              </Row>
              <Row className="title-row" justify="center" align="middle">
                <Col className="title">MetaMask</Col>
              </Row>
              <Row className="description-row" justify="center" align="middle">
                <Col className="description">Connect to your MetaMask Wallet</Col>
              </Row>
            </Col>
          </Row>
          <Divider style={{ margin: 0, padding: 0 }} />
          <Row className="wallet-button bot" justify="center" align="middle" onClick={onWCSelect}>
            <Col span={24}>
              <Row justify="center" align="middle">
                <Col span={5}>
                  <Icon component={() => <img alt="walletConnect" src={walletConnectIcon} />} />
                </Col>
              </Row>
              <Row className="title-row" justify="center" align="middle">
                <Col className="title">Wallet Connect</Col>
              </Row>
              <Row className="description-row" justify="center" align="middle">
                <Col className="description">Scan with Wallet Connect to connect</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <ToastContainer />
    </>
  );
}

export default SelectWallet;
