import './style.scss';

import { Col, Modal, Row } from 'antd';
import { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

export enum PreRegisterType {
  DEFAULT = 0,
  ADVERTISERS,
}

type PreRegisterModalProps = {
  preRegisterType: PreRegisterType;
  isModalVisible: boolean;
  handleCancel: () => void;
};
const PreRegisterModal = ({ preRegisterType, isModalVisible, handleCancel }: PreRegisterModalProps) => {
  const [text, setText] = useState('');
  const [isFormCreated, setIsFormCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isModalVisible && !isFormCreated) {
      setIsLoading(true);

      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          region: 'na1',
          portalId: '21538426',
          formId: '8389a147-6fd6-430a-a677-af5a66cd3c91',
          target: '#hubspotForm',
        });
      }

      setIsFormCreated(true);
      setIsLoading(false);
    }

    switch (preRegisterType) {
      case PreRegisterType.DEFAULT:
        setText('Pre Register for VenVuu and get ready to Reach for the Metaverse!');
        break;
      case PreRegisterType.ADVERTISERS:
        setText('Pre Register for the VenVuu Advertisers Program and get ready to Reach for the Metaverse!');
        break;
      default:
        setText('Pre Register for VenVuu and get ready to Reach for the Metaverse!');
        break;
    }
  }, [isFormCreated, isModalVisible, preRegisterType]);

  return (
    <>
      <Modal visible={isModalVisible} footer={null} centered={true} closable={false} bodyStyle={{ padding: 0, margin: 0 }} onCancel={handleCancel}>
        {isLoading ? (
          <Row align="middle" justify="center" style={{ padding: 30 }}>
            <ClipLoader color={'#d5b1ff'} loading={true} size={150} />
          </Row>
        ) : (
          <Row className="modal-box" justify="center" align="middle">
            <div className="modal-content">
              <div className="box">
                <Row justify="center">
                  <Col>{text}</Col>
                </Row>
                <hr style={{ border: '1px solid grey' }} />
                <div id="hubspotForm" />
              </div>
            </div>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default PreRegisterModal;
