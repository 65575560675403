import './style.scss';
import { Col, Modal, Row } from 'antd';
import { useEffect, useState } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import SelectWallet from '../SelectWallet';
import { nft_pay_script } from '../../../../constants';
import { ethers } from 'ethers';
import { rpc_url, react_app_mint_address, MINT_STATE } from '../../../../constants/index';

type ConnectProps = {
  onConnectMetaSuccess: (provider: Web3Provider, account: string) => void;
  onConnectWCSuccess: (provider: Web3Provider, account: string) => void;
};
function Connect({ onConnectMetaSuccess, onConnectWCSuccess }: ConnectProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [nftPayDisabled, setNftPayDisabled] = useState(false);

  const useCreditCardClicked = () => {
    const script = document.createElement('script');
    script.src = nft_pay_script;
    script.id = 'creatify_script';
    script.async = true;
    script.onload = () => {
      if ((window as any).show_creatify_popup) {
        (window as any).show_creatify_popup();
      }
    };
    window.document.body.appendChild(script);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const abi = ['function status() view returns (uint8)'];
    const contract = new ethers.Contract(react_app_mint_address, abi, new ethers.providers.JsonRpcProvider(rpc_url));

    contract
      .status()
      .then((status: number) => {
        if (status !== MINT_STATE.PUBLIC) {
          setNftPayDisabled(true);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  });

  return (
    <>
      <Modal visible={isModalVisible} footer={null} centered={true} closable={false} bodyStyle={{ padding: 0, margin: 0 }} onCancel={handleCancel}>
        <SelectWallet onConnectMetaSuccess={onConnectMetaSuccess} onConnectWCSuccess={onConnectWCSuccess} />
      </Modal>
      <Row>
        <Col span={24}>
          <Row className="button-row" justify="center" align="middle">
            <Col>
              <div className="my-button" onClick={nftPayDisabled ? undefined : useCreditCardClicked} style={nftPayDisabled ? { opacity: '0.5' } : undefined}>
                <Row justify="center" align="middle">
                  <Col className="pay-with-credit-card-label">Pay With Credit Card</Col>
                </Row>
                <Row className="no-wallet-needed-row" justify="center" align="middle">
                  <Col>
                    <i className="no-wallet-needed-label">{nftPayDisabled ? 'Public mint not started' : 'No wallet needed'}</i>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="button-row" justify="center" align="middle">
            <Col>
              <div className="connect-wallet-button" onClick={() => setIsModalVisible(true)}>
                Connect Wallet
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Connect;
