// importing separately as we can have 10k records
import { whitelist as _whitelist } from './whitelist';

export const CanMint: boolean = true;

// dev vars
export const react_app_env: string = 'dev';
export const react_app_mint_address: string = '0xb905EA6AB52F9508A7189FAA4CeBC469dFfCFD14';
export const rpc_url: string = 'https://eth-rinkeby.alchemyapi.io/v2/4hiJDQPwQvkRrlRLgIq3WQJR0GesdViw';
export const rpc_id: number = 4;
export const rpc_name: string = 'Rinkeby';
export const nft_pay_script: string = 'https://staging.creatify.art/libs/iframe_inject.js?contract_uuid=6ae017ff-749a-4186-9f34-f09f49addbc1';
export const nft_header_img: string = 'https://int-nft-venvuu.s3.amazonaws.com/partner/gen0/assets/partner-gen0-placeholder.mp4';

// prod vars
// export const react_app_env: string = 'prod';
// export const react_app_mint_address: string = '0x560b67c984ea4f300A33c55b5F114e1d5eBEcEb3';
// export const rpc_url: string = 'https://eth-mainnet.alchemyapi.io/v2/CQLNcbyW59H4RSIVPiJJKLrJv_JY3vro';
// export const rpc_id: number = 1;
// export const rpc_name: string = 'Ethereum Mainnet';
// export const nft_pay_script: string = 'https://payments.creatify.art/libs/iframe_inject.js?contract_uuid=48356217-f744-49b7-ad3d-4d4dffb28334';
// export const nft_header_img: string = 'https://prod-nft-venvuu.s3.amazonaws.com/partner/gen0/assets/partner-gen0-placeholder.mp4';

export const MINT_STATE = {
  DYNAMIC: -1,
  INACTIVE: 0,
  WHITELIST: 1,
  PUBLIC: 2,
  ENDED: 3,
};

export const CURRENT_MINT_STATE = MINT_STATE.DYNAMIC;
export const whitelist: String[] = _whitelist;
export const publicStartTime = Date.UTC(2022, 4, 9, 17);
export const whitelistStartTime = Date.UTC(2022, 4, 9, 12);
