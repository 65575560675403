import './style.scss';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="has-background-dark has-text-link-light">
      <section className="section">
        <div className="container">
          <div className="columns is-mobile">
            <div className="column is-6">
              <Link to="/" className="is-inline-block">
                <img className="logo-foot" src="img/logo-foot.svg" alt="Reach for the Metaverse" />
              </Link>

              <div className="py-4 is-hidden-mobile"></div>
              <p className="is-hidden-mobile">
                &copy; VenVuu, A
                <a href="https://datchat.com" target="_blank" className="footlink datlink" rel="noopener noreferrer">
                  DatChat
                </a>
                Company 2022
              </p>
            </div>
            <div className="column is-6">
              <div className="is-flex is-flex-wrap-wrap">
                <ul className="ml-auto mb-4 is-flex is-flex-wrap-wrap is-align-items-center">
                  <li>
                    <a className="footlink datlink mr-3" href="https://dat.chat/p/venvuu" target="_blank" rel="noopener noreferrer">
                      <i className="fak fa-datchat-square fa-2x"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/Venvuu_Datchat" target="_blank" className="footlink mr-3" rel="noopener noreferrer">
                      <i className="fa-brands fa-twitter-square fa-2x"></i>
                    </a>
                  </li>
                  <li>
                    <a className="footlink" href="https://discord.gg/5dbNTPTR22" target="_blank" rel="noopener noreferrer">
                      <i className="fa-brands fa-discord fa-2x"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p className="is-hidden-tablet mt-4 has-text-center">
            <small>
              &copy; VenVuu, A
              <a href="https://datchat.com" target="_blank" className="footlink" rel="noopener noreferrer">
                DatChat
              </a>
              Company 2022
            </small>
          </p>
        </div>
      </section>
    </footer>
  );
};
export default Footer;
