import '../styles/FAQPage.scss';

import Lottie from 'react-lottie';

import { Col, Collapse, Row } from 'antd';
import VenVuuText from '../components/VenVuuText';
const Panel = (Collapse as any).Panel;
const Accordion = Collapse as any;

const FAQPage = () => {
  return (
    <>
      <Row justify="center" align="middle" gutter={50} style={{ margin: 20 }} className="hero-body box">
        <Col lg={10} xs={24}>
          <img src="img/logo-anim-black.svg" alt="Reach for the Metaverse" />
        </Col>
        <Col lg={9} xs={24}>
          <Lottie
            speed={0.1}
            options={
              {
                loop: true,
                path: 'https://assets4.lottiefiles.com/packages/lf20_t03tbmqu.json',
              } as any
            }
          />
        </Col>
      </Row>

      <section className="section team">
        <h1 className="title has-text-centered">Frequently Asked Questions</h1>
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-10-desktop">
              <Accordion
                bordered={false}
                accordion
                expandIcon={({ isActive }: any) => (isActive ? <i className="fa-light fa-square-xmark mr-1"></i> : <i className="fa-light fa-square-caret-down mr-1"></i>)}
              >
                <Panel header="What is an NFT?" key="1">
                  Non-fungible Tokens (NFTs) are digital assets that can be traded over the internet. NFTs are created and sold using cryptocurrency, which is a sort of digital cash that has an
                  encrypted key that is often a random string of digits. Companies are even developing their own NFTs as part of their marketing mix because NFTs provide a unique marketplace for
                  digital assets. Aside from that, NFTs give consumers a customizable option to store, control, and protect their personal information.
                </Panel>
                <Panel
                  header={
                    <>
                      What is <VenVuuText /> Dynamic NFT?
                    </>
                  }
                  key="2"
                >
                  <VenVuuText /> Dynamic NFT enables any Metaverse parcel owner to link their ad spots with the <VenVuuText /> protocol in a simple and easy manner. A new placement will be detected
                  automatically by the system, and advertising will be served immediately.
                </Panel>
                <Panel
                  header={
                    <>
                      Why should I invest in <VenVuuText /> Dynamic?
                    </>
                  }
                  key="3"
                >
                  Simply holding a <VenVuuText /> Dynamic NFT will earn rewards. As a <VenVuuText /> Dynamic NFT holder, you will automatically obtain access to our exclusive revenue program which
                  also rewards you for every ad paid in your NFT.
                </Panel>
                <Panel
                  header={
                    <>
                      How can I mint a <VenVuuText /> Dynamic NFT?
                    </>
                  }
                  key="4"
                >
                  You’ll be able to mint <VenVuuText /> Dynamic NFT on our website. This feature will be available on the launch date. You can use either your crypto wallet or a credit card.
                </Panel>
                <Panel header="When will minting begin?" key="5">
                  Generation Zero: closed <br /> Generation One: TBD
                </Panel>
                <Panel header="What will be the mint price?" key="6">
                  0.2 ETH
                </Panel>
                <Panel header="Can I pay with a Credit Card instead of crypto?" key="7">
                  If you don’t have a wallet with ETH, don’t worry. You can also pay with a credit card.
                </Panel>
                <Panel
                  header={
                    <>
                      How will I receive my earnings from <VenVuuText /> Dynamic?
                    </>
                  }
                  key="9"
                >
                  We can pay you via ETH, USDC, or in FIAT via PayPal.
                </Panel>
                <Panel header="What is the total number available to mint?" key="10">
                  5,000 NFTs
                </Panel>
                <Panel header="What Blockchain Hosts the Project?" key="11">
                  Ethereum
                </Panel>
                <Panel header="What are the secondary royalty fees?" key="12">
                  0% Royalty fees
                </Panel>
                <Panel header="What does the term “Mint” mean?" key="13">
                  To put it another way, "minting" an NFT means creating a token and making it available for purchase on the blockchain.
                </Panel>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQPage;
