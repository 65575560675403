import './style.scss';
import { Col, Row, Divider } from 'antd';
import { useEffect, useState } from 'react';
import ConnectPage from './Components/Connect';
import { Web3Provider } from '@ethersproject/providers';
import { MintContract } from '../../contracts/mint-nft';
import Mint from './Components/Mint';
import { toChecksumAddress } from '../../utils';
import { ethers } from 'ethers';
import { react_app_env } from '../../constants';
import MintHeader from './Components/MintHeader';
import Confetti from 'react-confetti';

enum ScreenState {
  CONNECT = 0,
  MINT = 1,
}

function MintForm() {
  const [currentScreenState, setCurrentScreenState] = useState<ScreenState>(ScreenState.CONNECT);

  const [walletAddress, setWalletAddress] = useState<string>('');
  const [mintContract, setMintContract] = useState<MintContract | null>(null);
  const [provider, setProvider] = useState<Web3Provider | null>(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  const connect = () => {
    setCurrentScreenState(ScreenState.MINT);
  };

  const onConnectMetaSuccess = (provider: Web3Provider, account: string) => {
    connect();

    setProvider(provider);
    let contract = new MintContract(provider);
    setMintContract(contract);

    setWalletAddress(account);
  };

  const onConnectWCSuccess = (provider: Web3Provider, account: string) => {
    connect();

    setProvider(provider);
    let contract = new MintContract(provider);
    setMintContract(contract);

    setWalletAddress(account);
  };

  const onMintSuccess = () => {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 5000);
  };

  const displayScreen = () => {
    switch (currentScreenState) {
      case ScreenState.CONNECT:
        return <ConnectPage onConnectMetaSuccess={onConnectMetaSuccess} onConnectWCSuccess={onConnectWCSuccess} />;
      case ScreenState.MINT:
        return <Mint walletAddress={walletAddress} mintContract={mintContract} provider={provider} onMintSuccess={onMintSuccess} />;
      default:
        return 'unknown';
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', function (accounts: any[]) {
        let address = toChecksumAddress(accounts[0]);
        setWalletAddress(address);
      });

      window.ethereum.on('networkChanged', async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const providerNetwork = await provider.getNetwork();

        let requiredNet = react_app_env !== 'prod' ? 'rinkeby' : 'homestead';

        if (requiredNet !== providerNetwork.name) {
          setCurrentScreenState(ScreenState.CONNECT);
        } else {
          setCurrentScreenState(ScreenState.MINT);
        }
      });
    }

    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);

    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [walletAddress]);

  return (
    <>
      {showConfetti ? (
        <>
          <Confetti width={windowSize.width} height={windowSize.height} numberOfPieces={700} />
        </>
      ) : (
        <></>
      )}
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col>
          <Row justify="center" align="middle" style={{ padding: '10px 0' }}>
            <Col span={24}>
              <MintHeader />
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col span={24}>{displayScreen()}</Col>
          </Row>
          <Row justify="center" align="middle" style={{ marginTop: '25px', marginBottom: '25px' }}>
            <Col lg={12} xs={20} sm={20} xl={12} md={12}>
              <Divider style={{ backgroundColor: 'grey' }} />
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col lg={12} xs={20} sm={20} xl={12} md={12}>
              <h1 style={{ fontSize: 24 }}>Details</h1>
              <ul style={{ listStyleType: 'circle', fontSize: 16 }}>
                <li style={{ padding: '10px 0' }}>
                  Our whitelisted partners will have an opportunity to mint first during the whitelist period of 8am-1pm EST on May 9th. This sale requires you to mint directly to a web3 eth wallet
                  that you have access to. There is a limit of 20 nfts per whitelisted address. This period of the mint does not accept credit cards, you will get the chance to use a credit card
                  during the public mint.
                </li>
                <li style={{ padding: '10px 0' }}>
                  The public sale will begin at 1pm est on May 9th and will accept eth payments via a web3 wallet and credit cards. Our partner, NFTPay, will send an email to the one you specify with
                  a link on how to redeem the nft. If you pay with crypto, the nft will be minted directly to the purchasing wallet.{' '}
                </li>
                <li style={{ padding: '10px 0' }}>For questions related to credit card purchases or transferring your purchase to your wallet, please email support@nftpay.xyz</li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default MintForm;
