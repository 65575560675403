import './style.scss';

import { Row, Col } from 'antd';
import VenVuuText from '../../../VenVuuText';
import ReactPlayer from 'react-player';
import { nft_header_img, publicStartTime } from '../../../../constants';
import Countdown from 'antd/lib/statistic/Countdown';
const Player: any = ReactPlayer;

const MintHeader = () => {
  const onCountdownExpire = () => {
    console.log('DONE');
  };

  return (
    <>
      <Row justify="center">
        <Col span={24}>
          <Countdown
            valueStyle={{ color: '#884bca', fontSize: '2.5em', textAlign: 'center', fontWeight: '600', marginBottom: '50px', textTransform: 'uppercase' }}
            prefix="Public Sale Begins:"
            value={publicStartTime}
            onFinish={onCountdownExpire}
          />
        </Col>
      </Row>
      <Row className="title-row" justify="center">
        <Col>
          <VenVuuText /> Dynamic - Gen 0
        </Col>
      </Row>
      <Row className="video-row" justify="center">
        <Col style={{ margin: 0, padding: 0 }}>
          <Player
            playsinline={true}
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            onContextMenu={(e: any) => e.preventDefault()}
            height={300}
            url={nft_header_img}
            muted={true}
            playing={true}
            width="100%"
            loop={true}
          />
        </Col>
      </Row>
    </>
  );
};
export default MintHeader;
