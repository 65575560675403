import './style.scss';

import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Divider, Row, Slider, Statistic } from 'antd';
import Icon from 'react-crypto-icons';
import ClipLoader from 'react-spinners/ClipLoader';
import { MintContract } from '../../../../contracts/mint-nft';
import { Web3Provider, TransactionReceipt } from '@ethersproject/providers';
import { ToastContainer, toast } from 'react-toastify';
import { isWhitelisted, generateMerkleTree, getMerkleProof } from '../../../../common/whitelist';
import { whitelist } from '../../../../constants/whitelist';
import CoinGecko from 'coingecko-api';
import MintComplete from '../MintComplete';
import { CURRENT_MINT_STATE, MINT_STATE, publicStartTime } from '../../../../constants';

const { Countdown } = Statistic;

const coinGecko = new CoinGecko();

enum MintStatus {
  READY = 0,
  SUCCESS = 1,
  ERROR = 2,
}

type MintProps = {
  walletAddress: string;
  mintContract: MintContract | null;
  provider: Web3Provider | null;
  onMintSuccess: () => void;
};
function Mint({ walletAddress, mintContract, provider, onMintSuccess }: MintProps) {
  const [name, setName] = useState<string>('');
  const [ethPrice, setEthPrice] = useState<number>(0);
  const [nftPrice, setNftPrice] = useState<number>(0);
  const [nftCount, setNftCount] = useState<number>(1);
  const [mintStatus, setMintStatus] = useState<MintStatus>(MintStatus.READY);
  const [mintState, setMintState] = useState(MINT_STATE.INACTIVE);
  const [whitelisted, setWhitelisted] = useState<boolean>(false);
  const [totalMints, setTotalMints] = useState(0);
  const [mintsMinted, setMintsMinted] = useState(0);
  const [maxMintQuantity, setMaxMintQuantity] = useState(0);
  const [errorText, setErrorText] = useState('');
  const [transactionReciept, setTransactionReciept] = useState<TransactionReceipt | null>(null);
  const [isMinting, setIsMinting] = useState(false);

  const onSliderNftCountChange = (count: number) => [setNftCount(count)];

  const getFormattedWalletAddress = () => {
    const start = `${walletAddress.substring(0, 6)}`;
    const end = `${walletAddress.substring(walletAddress.length - 4, walletAddress.length)}`;
    return `${start}...${end}`;
  };

  const getMintStatusStyle = () => {
    return {
      background: mintStatus === MintStatus.READY ? '#edf4ff' : mintStatus === MintStatus.SUCCESS ? '#8df9be' : '#ffb1b4',
      border: mintStatus === MintStatus.READY ? '#b2c1d8' : mintStatus === MintStatus.SUCCESS ? '#008817' : '#ff5a6d',
      color: mintStatus === MintStatus.READY ? '#144491' : mintStatus === MintStatus.SUCCESS ? '#008817' : '#c6001a',
      text: mintStatus === MintStatus.READY ? 'Ready to mint' : mintStatus === MintStatus.SUCCESS ? 'Successfully minted!' : `${errorText}`,
    };
  };

  const getEtherPrice = async () => {
    const priceResponse = await coinGecko.simple.price({
      ids: ['ethereum'],
      vs_currencies: ['usd'],
    });
    return priceResponse.data.ethereum.usd;
  };

  const validateConnections = async () => {
    let pingResponse
    try {
      pingResponse = await coinGecko.ping();
    }catch(error) {
      console.log('error fetching eth price from coingecko')
    }
    
    if (!pingResponse || pingResponse.code !== 200) {
      setMintStatus(MintStatus.ERROR);
      setErrorText('Could not get current ETH price.');
      return false;
    }
    setErrorText('');
    return true;
  };

  const getContractMintData = useCallback(async () => {
    if (!mintContract) return;

    const ethPrice = await getEtherPrice();
    setEthPrice(parseFloat(ethPrice));

    setName(await mintContract?.getName());

    const maxQuantity = await mintContract?.getMaxMintQuantity();
    setMaxMintQuantity(maxQuantity || 0);

    if (CURRENT_MINT_STATE === MINT_STATE.DYNAMIC) {
      const contractState = await mintContract?.getStatus();
      setMintState(contractState || MINT_STATE.INACTIVE);
    }

    if (mintState === MINT_STATE.WHITELIST) {
      const nftPrice = await mintContract?.getWlMintPrice();
      setNftPrice(nftPrice || 0);
    } else {
      const nftPrice = await mintContract?.getMintPrice();
      setNftPrice(nftPrice || 0);
    }

    const maxSupply = await mintContract?.getMaxSupply();
    setTotalMints(maxSupply || 0);

    const totalSupply = await mintContract?.getTotalSupply();
    setMintsMinted(totalSupply || 0);
  }, [mintContract, mintState]);

  const mintNow = async () => {
    if (!mintContract || !provider || mintStatus === MintStatus.ERROR) {
      return;
    }

    setIsMinting(true);

    let hash: string;
    try {
      if (mintState === MINT_STATE.WHITELIST) {
        const tree = generateMerkleTree(whitelist);
        const proof = getMerkleProof({
          tree: tree,
          whitelisted: walletAddress,
        });
        hash = await mintContract.wlMintNFT(nftCount, proof);
      } else {
        hash = await mintContract.mintNFT(nftCount);
      }

      let prom = provider.waitForTransaction(hash, 1);
      toast.promise(
        prom,
        {
          pending: 'Minting',
          success: 'Mint Successful!',
          error: 'Error Minting',
        },
        {
          position: 'bottom-right',
        }
      );
      let transactionReciept = await prom;
      setTransactionReciept(transactionReciept);
      onMintSuccess();
    } catch (err) {
      let message = 'Error Minting';
      if ((err as Error).message.includes('Exceeded allowed aggregate total')) {
        message = `Error: Max mint count of ${maxMintQuantity} reached.  Please wait until the whitelist period is over to mint more.`;
      }
      toast.error(message, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setIsMinting(false);
  };

  const mintButton = () => {
    let disabled = true;
    let displayText = 'Mint Inactive';
    let disabledClass = '-disabled';

    if (mintState === MINT_STATE.ENDED || totalMints === mintsMinted) {
      displayText = 'Minting Period Has Ended';
    } else if (mintState === MINT_STATE.PUBLIC) {
      disabled = false;
      disabledClass = '';
      displayText = 'Mint Now';
    }

    if (mintState === MINT_STATE.WHITELIST) {
      if (whitelisted) {
        disabled = false;
        disabledClass = '';
        displayText = 'Whitelist Mint';
      } else {
        const intervalID = setInterval(async () => {
          try {
            const currState = await mintContract?.getStatus();
            if (currState !== MINT_STATE.WHITELIST) {
              setMintState(currState);
              clearInterval(intervalID);
            }
          } catch (err) {
            console.log(err);
          }
        }, 5 * 1000);

        return (
          <Button className={'wallet-connected-button' + disabledClass} onClick={mintNow} disabled={true} type="primary" size="large">
            <Row align="middle" justify="center" style={{ paddingTop: '2px' }}>
              <Col span={24}>
                <Countdown prefix="Public Begins:" value={publicStartTime} onFinish={onCountdownExpire} />;
              </Col>
            </Row>
          </Button>
        );
      }
    }

    return (
      <Button className={'wallet-connected-button' + disabledClass} onClick={mintNow} disabled={disabled || isMinting} type="primary" size="large">
        {displayText}
      </Button>
    );
  };

  const onCountdownExpire = async () => {
    console.log('done');
  };

  const disableAll = () => {
    return isMinting || mintState === MINT_STATE.ENDED || mintState === MINT_STATE.INACTIVE || mintsMinted === totalMints || (mintState === MINT_STATE.WHITELIST && !whitelisted);
  };

  useEffect(() => {
    generateMerkleTree(whitelist);
    // const tree = generateMerkleTree(whitelist);
    // console.log(tree.getHexRoot());

    while (!validateConnections()) {}

    if (CURRENT_MINT_STATE !== MINT_STATE.DYNAMIC) {
      setMintState(CURRENT_MINT_STATE);
    } else {
      mintContract
        ?.getStatus()
        .then((currState) => {
          console.log(currState);
          setMintState(currState);
        })
        .catch((err) => {
          console.log(err);
          setMintState(MINT_STATE.INACTIVE);
        });
    }

    setWhitelisted(false);
    setIsMinting(false);

    if (isWhitelisted(walletAddress)) {
      setWhitelisted(true);
    }

    if (totalMints === 0) getContractMintData();
    const interval = setInterval(async () => {
      getContractMintData();
    }, 5 * 1000);
    return () => clearInterval(interval);
  }, [walletAddress, getContractMintData, totalMints, mintContract]);

  const mintStatusStyle = getMintStatusStyle();

  if (!mintContract) {
    return <></>;
  }

  // Loading screen
  if (name === '' || nftPrice === 0 || totalMints === 0 || maxMintQuantity === 0) {
    return (
      <Row justify="center" align="middle">
        <Col>
          <ClipLoader color={'#d5b1ff'} loading={true} size={150} />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row justify="center" align="middle">
        <Col className="mint-box-wrapper" span={5}>
          <div style={{ opacity: disableAll() ? '0.5' : '1.0' }}>
            <Row
              className="status-box"
              justify="center"
              style={{
                background: mintStatusStyle.background,
                borderColor: mintStatusStyle.border,
                color: mintStatusStyle.color,
              }}
            >
              <Col>
                <b>{getFormattedWalletAddress()}</b> Connected
              </Col>
              <Divider />
              <Col>
                <span className="status-label">Status:</span> {mintStatusStyle.text}
              </Col>
            </Row>
            <Divider />
            <>
              {mintStatus !== MintStatus.ERROR ? (
                <>
                  <Row className="mint-count-box">
                    <Col className="label" span={24}>
                      How many would you like to mint?
                    </Col>
                    <Col span={24}>
                      <Slider disabled={disableAll()} defaultValue={1} min={1} max={maxMintQuantity} tooltipVisible={false} onChange={onSliderNftCountChange} />
                      <Row>
                        <Col>1</Col>
                        <Col offset={21} flex={'right'}>
                          {maxMintQuantity}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="selected-nft-counts-box">
                    <Col className="nft-count-label" span={13}>
                      {nftCount} NFT{nftCount > 1 ? 's' : ''} (x {nftPrice}
                      <Icon name="eth" size={10} />)
                    </Col>
                    <Col span={11} className="total-price-label">
                      <Row justify="end">
                        <Col span={20}>Total: {(nftPrice * nftCount).toFixed(3)}</Col>
                        <Col span={1}>
                          <Icon name="eth" size={15} />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>~${(ethPrice * (nftPrice * nftCount)).toFixed(2)}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <Divider />
                  <Row className="submit-box" align="middle" justify="center">
                    <Col>{mintButton()}</Col>
                  </Row>
                </>
              ) : (
                <></>
              )}
            </>
            {transactionReciept !== null ? <MintComplete transactionReciept={transactionReciept} provider={provider} /> : <></>}
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </>
  );
}

export default Mint;
