const VenVuuText = () => {
  return (
    <>
      <span className="name">
        VenV
        <span className="u">
          <i>u</i>
        </span>
        <span className="u">
          <i>u</i>
        </span>
      </span>
    </>
  );
};

export default VenVuuText;
