import '../styles/WhitepaperPage.scss';
import Lottie from 'react-lottie';
import { Col, Row } from 'antd';
import VenVuuText from '../components/VenVuuText';

const WhitepaperPage = () => {
  return (
    <>
      <Row justify="center" align="middle" gutter={50} style={{ margin: 20 }} className="hero-body box">
        <Col lg={10} xs={24}>
          <img src="img/logo-anim-black.svg" alt="Reach for the Metaverse" />
        </Col>
        <Col lg={9} xs={24}>
          <Lottie
            speed={0.1}
            options={
              {
                loop: true,
                path: 'https://assets3.lottiefiles.com/packages/lf20_wIDnav.json',
              } as any
            }
          />
        </Col>
      </Row>

      <section className="section network">
        <div className="container">
          <div className="columns is-multiline is-centered">
            <div className="column is-8 is-8-desktop">
              <div className="block mb-5">
                <h1 className="mainhead mb-2">Reach for the Metaverse</h1>
                <p className="has-text-grey mb-4">
                  <VenVuuText /> is an innovative Metaverse advertising platform that allows advertisers and metaverse landowners to connect using our proprietary metaverse ad network and dynamic NFT
                  technology. Landowners have an easy and consolidated way to decide which campaigns to run to generate revenue from their holdings. Advertisers can run campaigns that target the land
                  parcels they want to reach simultaneously across multiple metaverses. We’re empowering companies to get their presence in the metaverse and generating revenue for metaverse
                  landowners and NFT holders.
                </p>
                <p className="has-text-grey mb-4">
                  Our technology allows advertisers to create an ad campaign and place it in multiple different metaverses, reaching a wider audience and generating more impressions. This all occurs
                  in real-time on the fly, with no need for any additional software or plugins.
                </p>
                <p className="has-text-grey mb-4">
                  In the metaverse, native VR advertising has the most promise for marketers. Brands can design full-scale experiences that tell a story, allow customers to interact with their
                  product, and ultimately make a purchase – all while remaining in the metaverse.
                </p>
                <p className="has-text-grey mb-4">
                  Global advertising spending in 2021 reached $689B and the forecast data indicates that the ad spending worldwide will reach $850B by the end of 2024, and we are bringing the bridge
                  to get a good part of that into the metaverse.
                </p>
              </div>
              <div className="block mb-5">
                <h2 className="mainhead mb-2">
                  <VenVuuText />
                  's advertising integration is simple and relies on NFT.
                </h2>
                <p className="has-text-grey mb-4">
                  <VenVuuText /> NFT enables any Metaverse parcel owner to link their ad spots with the <VenVuuText /> protocol in a simple and easy manner.
                </p>
                <p className="has-text-grey mb-4">A new placement will be detected automatically by the system, and advertising will be served immediately.</p>
                <p className="has-text-grey mb-4">
                  The entire procedure does not necessitate any prior knowledge of media or coding. Those who want to track their advertising performance can create a <VenVuuText /> account and
                  receive real-time campaign data in an easy-to-export format, including revenue, clicks, views, CPM, CPA, and other metrics.
                </p>
                <p className="has-text-grey mb-4">Owners can alter ad sizes, placements, and takedown ads they find unsuitable at any time, helping to improve Metaverse advertising.</p>
                <p className="has-text-grey mb-4">
                  When you join <VenVuuText />, whether it's through purchasing an NFT or simply joining our Discord, you become a member of our community right away. On this exciting journey into
                  Web3, a global community that empowers and supports one another. As we explore the metaverse together, we're concentrating on forming meaningful relationships.
                </p>
                <p className="has-text-grey mb-4">
                  You automatically obtain access to our exclusive <VenVuuText /> revenue program as a<VenVuuText /> NFT holder, which rewards you for every ad paid in your NFT, even you will get
                  rewarded just to hold a <VenVuuText /> NFT. As we seek innovative ways to reward our members with extra perks, our program will continue to evolve.
                </p>
              </div>
              <div className="block mb-5">
                <h2 className="mainhead mb-2">What Will Advertising Look Like in the Metaverse?</h2>
                <p className="has-text-grey mb-4">
                  There are two perspectives on the advertising metaverse opportunity. To begin with, it's another channel in your marketing mix, similar to having a branded mobile app, Google banner
                  advertisements, an analytics-enabled website, and a social media presence (both organic and sponsored).
                </p>
                <p className="has-text-grey mb-4">
                  The metaverse, too, will have its own distinct ad forms, complete with marketing attribution, allowing advertisers to track visits and invest wisely.
                </p>
                <p className="has-text-grey mb-4">
                  Second, the metaverse might be seen as a new storytelling medium. You can have immersive ad experiences that tell the brand story in addition to typical advertising that appears on
                  Google, Facebook, LinkedIn, and other sites.
                </p>
              </div>
              <div className="block mb-5">
                <h2 className="mainhead mb-2">Marketers' Guide to the Metaverse</h2>
                <p className="has-text-grey mb-4">
                  The metaverse can be defined as a persistent, 3D, and virtual area where consumers can spend their time and be targeted with brand-related content and sales enablement strategies in
                  the context of advertising and marketing.
                </p>
                <p className="has-text-grey mb-4">
                  Today's closest equivalent is social media, where users check-in, communicate with their friends, family, and peer network – and are exposed to companies, advertising, sponsored
                  content, and calls to conversion as a result of their interactions.
                </p>
                <p className="has-text-grey mb-4">
                  Social media has grown over time to include direct-to-customer storefronts where you can pay for things online and have them delivered to your home.
                </p>
                <p className="has-text-grey mb-4">Similarly, the metaverse might contain 3D "stores" where you can not only browse commercials but actually try out things before making a purchase.</p>
              </div>
              <div className="block mb-5">
                <h2 className="mainhead mb-2">Advertising on the Metaverse is unavoidable.</h2>
                <p className="has-text-grey mb-4">
                  Advertising may be found in every aspect of life, and it has already made an appearance in metaverses, but in a very simple version. It will eventually develop on a massive scale
                  there. Furthermore, because the virtual world is still in its infancy, events and experiences developed for the Metaverse require advertising in order to attract visitors – and
                  flourish.
                </p>
                <p className="has-text-grey mb-4">
                  Landowners who invested in the different metaverses may be seeking methods to boost their return on investment by monetizing their parcels through sponsored advertising.
                </p>
                <p className="has-text-grey mb-4">Up-and-coming ad networks may require a more automated exchange and ad-buying process to automate their ad placements.</p>
                <p className="has-text-grey mb-4">
                  How we connect with brands will be completely transformed by the metaverse. The metaverse will have an impact on industries, but global consumer brands stand to benefit the most from
                  it. Because of its nature, the immersive internet will allow consumers to connect with brands for longer periods of time as long as the experiences are distinctive, creative, and
                  sociable.
                </p>
                <p className="has-text-grey mb-4">
                  The way to do marketing will no longer be business as usual in the metaverse. Instead, it will be enjoyable, with marketers limited only by their imagination and resources. Only the
                  sky is the limit. For consumers, this means that in the next years, we may expect some wonderful immersive experiences, potentially enhancing brand loyalty for those who are brave
                  enough to venture into the metaverse first.
                </p>
              </div>
              <div className="block mb-5">
                <h2 className="mainhead mb-2">
                  Who is behind <VenVuuText />?
                </h2>
                <p className="has-text-grey mb-4">
                  <VenVuuText /> is both owned and operated by DatChat, Inc., and developed using its patented DRM and encryption technology. (Nasdaq:DATS)
                </p>
                <p className="has-text-grey mb-4">
                  DatChat Inc. is a blockchain, digital rights management, cybersecurity, and social media company that not only focuses on protecting our privacy on our devices but also protecting
                  our information after we have shared it with others. We believe an individual's right to privacy should not end the moment they click “send”, and that we all deserve the same right
                  to privacy online that we enjoy in our own living rooms. Our flagship product, the DatChat Messenger &amp; Private Social Network, is a private platform and mobile application that
                  provides users the ability to communicate and share with the privacy and protection they deserve.
                </p>
                <p className="has-text-grey mb-4">
                  Our patented technology allows users to exercise control over their messages and posts, even after they are sent. Through our application, users can delete messages that they send on
                  their own device and the recipient’s device as well. There is no set time limit within which they must exercise this choice. A user can select at any time to delete a message
                  previously sent to a recipient’s device. In addition, users can delete entire conversations at any time, making it like the conversation never even happened. Additionally, our
                  application includes a screenshot protection system, which makes it virtually impossible for the recipient to screenshot a message or picture before it gets destroyed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhitepaperPage;
